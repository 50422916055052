"use client"

import { type FC } from "react"

import Heading from "@/components/typography/Heading"
import Paragraph from "@/components/typography/Paragraph"
import { Button } from "@/components/ui/button"
import useOnce from "@/hooks/useOnce"
import { logError } from "@/utils/error"

import { type ErrorBoundaryShellProps } from "./types"

/**
 * This component is used for manually rendering the default error UI. It's used in error.tsx
 * files, where react-error-boundary can't be used.
 */
const ErrorBoundaryShell: FC<ErrorBoundaryShellProps> = ({ error }) => {
  useOnce(() => {
    if (error) {
      logError(error)
    }
  })

  return (
    <div className="m-10 flex w-full flex-col items-center justify-center rounded-md border border-red-800 bg-red-200 p-10 dark:border-red-900/60 dark:bg-red-500/5">
      <Heading size={3} icon="error" className="mb-2">
        Oops, something went wrong
      </Heading>
      <Paragraph size="lg" className="mb-5 max-w-2xl text-center">
        We&rsquo;ve tracked down the bug and our team is working on squashing
        it. Please try reloading again soon or reach out to us on Discord if you
        need help.
      </Paragraph>
      <Button
        type="button"
        onClick={() => window.location.reload()}
        icon="reload"
        variant="secondary"
        size="lg"
      >
        Reload Page
      </Button>
    </div>
  )
}

export default ErrorBoundaryShell
