import * as Sentry from "@sentry/nextjs"

import { IS_PRODUCTION } from "@/constants"

const shouldIgnoreError = (error: Error) => {
  let shouldIgnore = false
  const isNetworkError =
    error.name === "TypeError" && error.message === "Failed to fetch"

  if (isNetworkError) {
    shouldIgnore = true
  }

  return shouldIgnore
}

export const logError = (error: Error) => {
  if (IS_PRODUCTION && !shouldIgnoreError(error)) {
    Sentry.captureException(error)
  } else {
    console.error(error)
  }
}
