import { type HeadingSizeMap } from "./types"

export const HEADING_SIZES: HeadingSizeMap = {
  1: "text-4xl font-semibold font-inter  ",
  2: "text-3xl font-medium  font-inter  ",
  3: "text-2xl font-inter  font-medium ",
  4: "text-xl",
  5: "text-lg",
  6: "text-base",
  7: "text-sm font-medium", // 14px
}
