import { type FC } from "react"

import Icon from "@/components/Icon"
import { cn } from "@/utils/cn"

import { HEADING_SIZES } from "./constants"
import { type HeadingProps } from "./types"

const Heading: FC<HeadingProps> = ({
  children,
  size,
  fontSize,
  className,
  icon,
}) => {
  const Tag = `h${size}` as keyof JSX.IntrinsicElements

  return (
    <Tag
      className={cn(
        "flex items-center gap-x-3 font-semibold ",
        fontSize ? HEADING_SIZES[fontSize] : HEADING_SIZES[size],
        className,
      )}
    >
      {icon && (
        <Icon
          type={icon}
          size={(fontSize ?? size) <= 3 ? "lg" : "md"}
          className={cn(
            "text-emerald-500 dark:text-emerald-200",
            icon === "error" && "text-red-500 dark:text-red-200",
          )}
        />
      )}
      {children}
    </Tag>
  )
}

export default Heading
